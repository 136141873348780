import { reactive } from 'vue'
import { Firestore, POST, desconexión, desconectar } from './global'
import { error, IError } from './error'
import { texto, número, fecha, arreglo, notificación, obtenerListado } from './utilidades'
import { cuenta, RefCuenta } from './cuenta'
import firebase from "firebase";


interface ILlave {
	identificador: string
	activa: boolean
	cuenta: {
		identificador: string
	}
	ver: boolean
}

export const llaves = {
	estado: reactive({
    listado: [],
		listando: false,
		listadoInicializado: false
  }) as {
		listado: ILlave[]
		listando: boolean
		listadoInicializado: boolean
	},
	listar: async function() {
		let ref = Firestore.collection('llaves').where('cuenta.identificador', '==', cuenta.identificador)

		let constructor = function(d: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>): ILlave {
			let dts = d.data()
			let iCuenta = ''

			try {
				iCuenta = texto.opcional(dts.cuenta.identificador, 'Datos errados.')
			} catch (e) {
				console.error(e)
			}

			let datos: ILlave = {
				identificador: d.id,
				activa: dts.activa,
				cuenta: {
					identificador: iCuenta
				},
				ver: false
			}

			return datos
		}

		return obtenerListado(ref, constructor, llaves.estado)
	},
	listarUnaVez: async function() {
		if (llaves.estado.listadoInicializado) {
			return Promise.resolve()
		}

		return llaves.listar()
	}
}

interface IEvento {
	identificador: string
	fecha: Date | null
	fiabilidad: number | null
	variables: any
	ente: {
		identificador: string
	}
	grupoDeEntes: {
		identificador: string
	},
	verificacion: {
		envio: {
			correoElectronico: {
				enviado: boolean
				direccion: string
			},
			sms: {
				enviado: boolean
				numero: string
			}
		},
		intentos: number,
		requerida: boolean,
		validada: boolean
	}
}

export const eventos = {
	estado: reactive({
    listado: [],
		listando: false,
		listadoInicializado: false,
		paginacion: {
			navegacion: [],
			ordenarPor: 'fecha',
			orden: 'desc',
			cantidadPorPagina: 100,
			indiceActual: -1,
			indiceFinal: -1,
		}
  }) as {
		listado: IEvento[]
		listando: boolean
		listadoInicializado: boolean
		paginacion: Paginacion
	},
	estadoFrio: {
		paginacion: {
			paginas: []
		},
		graficas: {
			eventosRecientes: {
				inicializado: false,
				totales: {
					inicializado: false,
					registros: [],
					datos: [],
					llamada: undefined
				},
				autenticacion: {
					inicializado: false,
					registros: [],
					datos: [],
					llamada: undefined
				},
				fiabilidad: {
					inicializado: false,
					registros: [],
					datos: [],
					llamada: undefined
				}
			}
		}
	} as {
		paginacion: {
			paginas: PaginacionPagina[]
		},
		graficas: {
			eventosRecientes: {
				inicializado: boolean
				totales: {
					inicializado: boolean
					registros: Array<{
						fecha: Date
					}>
					datos: [string, number][]
					llamada: ((datos: [string, number][]) => void) | undefined
				},
				autenticacion: {
					inicializado: boolean
					registros: Array<{
						validada: boolean
						fecha: Date
					}>
					datos: [string, number, number][]
					llamada: ((datos: [string, number, number][]) => void) | undefined
				},
				fiabilidad: {
					inicializado: boolean
					registros: Array<{
						fiabilidad: number
						fecha: Date
					}>
					datos: [string, number][]
					llamada: ((datos: [string, number][]) => void) | undefined
				}
			}
		}
	},
	listar: async function(indicePagina: number) {
		eventos.estado.listando = true

		let rechazar = (e: IError) => {
			eventos.estado.listando = false
			notificación.error(e.msj)
			return Promise.reject()
		}

		let constructor = function(d: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>): IEvento {
			let D = d.data()
			let datos: IEvento = {
				identificador: d.id,
				fecha: fecha.opcional(D.fecha),
				fiabilidad: número.opcional(D.fiabilidad),
				variables: D.variables,
				ente: {
					identificador: D.ente.identificador,
				},
				grupoDeEntes: {
					identificador: D.grupoDeEntes.identificador
				},
				verificacion: {
					envio: {
						correoElectronico: {
							enviado: (D.verificacion && D.verificacion.envio && D.verificacion.envio.correoElectronico && D.verificacion.envio.correoElectronico.enviado) || false,
							direccion: (D.verificacion && D.verificacion.envio && D.verificacion.envio.correoElectronico && D.verificacion.envio.correoElectronico.direccion) || '',
						},
						sms: {
							enviado: (D.verificacion && D.verificacion.envio && D.verificacion.envio.sms && D.verificacion.envio.sms.enviado) || false,
							numero: (D.verificacion && D.verificacion.envio && D.verificacion.envio.sms && D.verificacion.envio.sms.numero) || '',
						}
					},
					intentos: (D.verificacion && D.verificacion.intentos) || 0,
					requerida: (D.verificacion && D.verificacion.requerida) || false,
					validada: (D.verificacion && D.verificacion.validada) || false
				}
			}

			return datos
		}

		try {
			let ref = RefCuenta.collection('eventos')
			await listar(this.estado, ref, indicePagina || 0, this.estadoFrio.paginacion.paginas, constructor)
		} catch (e) {
			return rechazar(error.n('[7322] Ocurrió un error.', e))
		}

		return Promise.resolve()
	},
	listarPaginaAnterior: async function() {
		return this.listar(this.estado.paginacion.indiceActual-1)
	},
	listarPaginaSiguiente: async function() {
		return this.listar(this.estado.paginacion.indiceActual+1)
	},
	inicializarRecientesTotales: function(actualizarGrafica: ((datos: [string, number][]) => void)) {
		this.estadoFrio.graficas.eventosRecientes.totales.inicializado = true
		this.estadoFrio.graficas.eventosRecientes.totales.llamada = actualizarGrafica
		if (this.estadoFrio.graficas.eventosRecientes.autenticacion.inicializado || this.estadoFrio.graficas.eventosRecientes.fiabilidad.inicializado) {
			if (this.estadoFrio.graficas.eventosRecientes.totales.datos.length) {
				this.estadoFrio.graficas.eventosRecientes.totales.llamada(this.estadoFrio.graficas.eventosRecientes.totales.datos)
			}
		} else {
			this.inicializarRecientes()
		}
	},
	inicializarRecientesAutenticacion: function(actualizarGrafica: ((datos: [string, number, number][]) => void)) {
		this.estadoFrio.graficas.eventosRecientes.autenticacion.inicializado = true
		this.estadoFrio.graficas.eventosRecientes.autenticacion.llamada = actualizarGrafica
		if (this.estadoFrio.graficas.eventosRecientes.totales.inicializado || this.estadoFrio.graficas.eventosRecientes.fiabilidad.inicializado) {
			if (this.estadoFrio.graficas.eventosRecientes.autenticacion.datos.length) {
				this.estadoFrio.graficas.eventosRecientes.autenticacion.llamada(this.estadoFrio.graficas.eventosRecientes.autenticacion.datos)
			}
		} else {
			this.inicializarRecientes()
		}
	},
	inicializarRecientesFiabilidad: function(actualizarGrafica: ((datos: [string, number][]) => void)) {
		this.estadoFrio.graficas.eventosRecientes.fiabilidad.inicializado = true
		this.estadoFrio.graficas.eventosRecientes.fiabilidad.llamada = actualizarGrafica
		if (this.estadoFrio.graficas.eventosRecientes.totales.inicializado || this.estadoFrio.graficas.eventosRecientes.autenticacion.inicializado) {
			if (this.estadoFrio.graficas.eventosRecientes.fiabilidad.datos.length) {
				this.estadoFrio.graficas.eventosRecientes.fiabilidad.llamada(this.estadoFrio.graficas.eventosRecientes.fiabilidad.datos)
			}
		} else {
			this.inicializarRecientes()
		}
	},
	desconectarRecientesTotales: function() {
		this.estadoFrio.graficas.eventosRecientes.totales.inicializado = false
		this.estadoFrio.graficas.eventosRecientes.totales.llamada = undefined
		this.estadoFrio.graficas.eventosRecientes.totales.registros = []
		this.estadoFrio.graficas.eventosRecientes.totales.datos = []
		if (!this.estadoFrio.graficas.eventosRecientes.autenticacion.inicializado && !this.estadoFrio.graficas.eventosRecientes.fiabilidad.inicializado) {
			this.estadoFrio.graficas.eventosRecientes.inicializado = false
			desconectar('eventos_recientes')
		}
	},
	desconectarRecientesAutenticacion: function() {
		this.estadoFrio.graficas.eventosRecientes.autenticacion.inicializado = false
		this.estadoFrio.graficas.eventosRecientes.autenticacion.llamada = undefined
		this.estadoFrio.graficas.eventosRecientes.autenticacion.registros = []
		this.estadoFrio.graficas.eventosRecientes.autenticacion.datos = []
		if (!this.estadoFrio.graficas.eventosRecientes.totales.inicializado && !this.estadoFrio.graficas.eventosRecientes.fiabilidad.inicializado) {
			this.estadoFrio.graficas.eventosRecientes.inicializado = false
			desconectar('eventos_recientes')
		}
	},
	desconectarRecientesFiabilidad: function() {
		this.estadoFrio.graficas.eventosRecientes.fiabilidad.inicializado = false
		this.estadoFrio.graficas.eventosRecientes.fiabilidad.llamada = undefined
		this.estadoFrio.graficas.eventosRecientes.fiabilidad.registros = []
		this.estadoFrio.graficas.eventosRecientes.fiabilidad.datos = []
		if (!this.estadoFrio.graficas.eventosRecientes.totales.inicializado && !this.estadoFrio.graficas.eventosRecientes.autenticacion.inicializado) {
			this.estadoFrio.graficas.eventosRecientes.inicializado = false
			desconectar('eventos_recientes')
		}
	},
	inicializarRecientes: function() {
		if (this.estadoFrio.graficas.eventosRecientes.inicializado) {
			return
		}

		this.estadoFrio.graficas.eventosRecientes.inicializado = true

		let tiempo = function() {
			let ahora = new Date()
			let hace8HE = new Date(ahora.getTime() - (8 * 60 * 60 * 1000))
			let hace8HR = new Date(hace8HE.getTime() - (hace8HE.getMinutes() * 60 * 1000) - (hace8HE.getSeconds() * 1000) - hace8HE.getMilliseconds())

			return {
				ahora,
				haceOchoHoras: {
					exactas: hace8HE,
					redondeadas: hace8HR
				}
			}
		}

		let contarPorHora = function(registros: { fecha: Date }[]): Map<number, number> {
			let horasMapeadas = new Map()
			for (let r of registros) {
				let h = r.fecha.getHours()
				let c = horasMapeadas.get(h) || 0
				horasMapeadas.set(h, c+1)
			}

			return horasMapeadas
		}

		desconectar('eventos_recientes')

		let dcnctr = RefCuenta.collection('eventos').where('fecha', '>=', tiempo().haceOchoHoras.redondeadas).onSnapshot((snapshot) => {
			let t = tiempo()
			let ahora = t.ahora
			let desde = t.haceOchoHoras.redondeadas.getTime()

			for (let i = this.estadoFrio.graficas.eventosRecientes.totales.registros.length-1 ; i >= 0 ; i--) {
				if (this.estadoFrio.graficas.eventosRecientes.totales.registros[i].fecha.getTime() < desde) {
					this.estadoFrio.graficas.eventosRecientes.totales.registros.splice(i, 1)
				}
			}

			for (let i = this.estadoFrio.graficas.eventosRecientes.autenticacion.registros.length-1 ; i >= 0 ; i--) {
				if (this.estadoFrio.graficas.eventosRecientes.autenticacion.registros[i].fecha.getTime() < desde) {
					this.estadoFrio.graficas.eventosRecientes.autenticacion.registros.splice(i, 1)
				}
			}

			for (let i = this.estadoFrio.graficas.eventosRecientes.fiabilidad.registros.length-1 ; i >= 0 ; i--) {
				if (this.estadoFrio.graficas.eventosRecientes.fiabilidad.registros[i].fecha.getTime() < desde) {
					this.estadoFrio.graficas.eventosRecientes.fiabilidad.registros.splice(i, 1)
				}
			}

			snapshot.docChanges().forEach(cambio => {
				if (cambio.type !== 'added' && cambio.type !== 'modified') {
					return
				}

				let f = fecha.opcional(cambio.doc.get('fecha'))
				if (!f) {
					return
				}

				let verif = cambio.doc.get('verificacion')
				if (verif && verif.requerida) {
					this.estadoFrio.graficas.eventosRecientes.autenticacion.registros.push({
						validada: !!verif.validada,
						fecha: f
					})
				}

				console.log('cambio.type', cambio.type, cambio.doc.id)

				if (cambio.type !== 'added') {
					return
				}

				let fiab = número.opcional(cambio.doc.get('fiabilidad'))
				if (fiab !== null && fiab >= 0) {
					this.estadoFrio.graficas.eventosRecientes.fiabilidad.registros.push({
						fiabilidad: fiab,
						fecha: f
					})
				} else {
					console.warn('resultado de fiabilidad inesperado', {
						'fiabilidad': fiab,
						'tipo': cambio.type,
						'datos': cambio.doc.data()
					})
				}

				this.estadoFrio.graficas.eventosRecientes.totales.registros.push({fecha: f})
			})

			let horasMapeadasTotales = contarPorHora(this.estadoFrio.graficas.eventosRecientes.totales.registros)
			let datosTotales: [string, number][] = []

			let horasMapeadasAutenticacionReqSiVal = contarPorHora(this.estadoFrio.graficas.eventosRecientes.autenticacion.registros.filter(r => r.validada))
			let horasMapeadasAutenticacionReqNoVal = contarPorHora(this.estadoFrio.graficas.eventosRecientes.autenticacion.registros.filter(r => !r.validada))
			let datosAutenticacion: [string, number, number][] = []

			let horasMapeadasFiabilidad = new Map()
			for (let r of this.estadoFrio.graficas.eventosRecientes.fiabilidad.registros) {
				let h = r.fecha.getHours()
				let obj = horasMapeadasFiabilidad.get(h) || {c: 0, s: 0}
				obj.s += r.fiabilidad
				obj.c++
				horasMapeadasFiabilidad.set(h, obj)
			}
			let datosFiabilidad: [string, number][] = []

			for (let i = 8 ; i >= 0 ; i--) {
				let h = new Date(ahora.getTime() - (i * 60 * 60 * 1000)).getHours()
				let hh = h > 12 ? `${h-12} p.m.` : `${h} a.m.`

				let ctddTtl = horasMapeadasTotales.get(h) || 0
				datosTotales.push([hh, ctddTtl])

				let ctddSi = horasMapeadasAutenticacionReqSiVal.get(h) || 0
				let ctddNo = horasMapeadasAutenticacionReqNoVal.get(h) || 0
				datosAutenticacion.push([hh, ctddSi, ctddNo])

				let dtsFia = horasMapeadasFiabilidad.get(h) || {c: 1, s: 0}
				datosFiabilidad.push([hh, dtsFia.s/dtsFia.c])
			}

			/*
			datosTotales[3][1] = 4 // pruebas ------------
			datosTotales[3][1] = 3 // pruebas ------------
			datosTotales[datosTotales.length-3][1] = 3 // pruebas ------------
			datosTotales[datosTotales.length-2][1] = 7 // pruebas ------------
			datosAutenticacion[datosAutenticacion.length-3][1] = 1 // pruebas ------------
			datosAutenticacion[datosAutenticacion.length-2][1] = 3 // pruebas ------------
			datosAutenticacion[datosAutenticacion.length-2][2] = 1 // pruebas ------------
			datosFiabilidad[5][1] = 0.735 // pruebas ------------
			*/

			console.log('datosTotales', datosTotales)
			console.log('datosAutenticacion', datosAutenticacion)
			console.log('datosFiabilidad', datosFiabilidad)

			if (
				this.estadoFrio.graficas.eventosRecientes.totales.llamada &&
				!arreglo.comparar(this.estadoFrio.graficas.eventosRecientes.totales.datos, datosTotales)
			) {
				this.estadoFrio.graficas.eventosRecientes.totales.llamada(datosTotales)
			} else {
				console.log('aaaa2')
			}

			if (
				this.estadoFrio.graficas.eventosRecientes.autenticacion.llamada &&
				!arreglo.comparar(this.estadoFrio.graficas.eventosRecientes.autenticacion.datos, datosAutenticacion)
			) {
				this.estadoFrio.graficas.eventosRecientes.autenticacion.llamada(datosAutenticacion)
			} else {
				console.log('bbbb2')
			}

			if (
				this.estadoFrio.graficas.eventosRecientes.fiabilidad.llamada &&
				!arreglo.comparar(this.estadoFrio.graficas.eventosRecientes.fiabilidad.datos, datosFiabilidad)
			) {
				this.estadoFrio.graficas.eventosRecientes.fiabilidad.llamada(datosFiabilidad)
			} else {
				console.log('cccc2')
			}

			this.estadoFrio.graficas.eventosRecientes.totales.datos = datosTotales
			this.estadoFrio.graficas.eventosRecientes.autenticacion.datos = datosAutenticacion
			this.estadoFrio.graficas.eventosRecientes.fiabilidad.datos = datosFiabilidad
		})

		desconexión('eventos_recientes', dcnctr)
	},
}

interface IEnte {
	identificador: string
	correoElectronico: string
	numeroTelefonico: string
	eventos: {
		masReciente: {
			fecha: Date
			identificador: string
		}
		masAntiguo: {
			fecha: Date
			identificador: string
		}
		cantidadTotal: number
	}
}

export const entes = {
	estado: reactive({
    listado: [],
		listando: false,
		listadoInicializado: false,
		paginacion: {
			navegacion: [],
			ordenarPor: 'creacion.fecha',
			orden: 'desc',
			cantidadPorPagina: 100,
			indiceActual: -1,
			indiceFinal: -1,
		}
  }) as {
		listado: IEnte[]
		listando: boolean
		listadoInicializado: boolean
		paginacion: Paginacion
	},
	estadoFrio: {
		paginacion: {
			paginas: [],
		}
	} as {
		paginacion: {
			paginas: PaginacionPagina[]
		}
	},
	listar: async function(grupoDeEntes: string, indicePagina: number) {
		entes.estado.listando = true

		let rechazar = (e: IError) => {
			entes.estado.listando = false
			notificación.error(e.msj)
			return Promise.reject()
		}

		let constructor = function(d: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>): IEnte {
			let dts = d.data()
			let correoElectronico = ''
			let numeroTelefonico = ''
			let evReFecha = new Date(0, 0, 0)
			let evReIdentificador = ''
			let evAnFecha = new Date(0, 0, 0)
			let evAnIdentificador = ''
			let evCanTot = 0

			try {
				correoElectronico = texto.opcional(dts.correoElectronico, 'Correo electrónico errado o faltante.')
				numeroTelefonico = texto.opcional(dts.numeroTelefonico, 'Número telefónico errado o faltante.')
				if (dts.eventos && dts.eventos.masReciente) {
					evReFecha = fecha.obligatorio(dts.eventos.masReciente.fecha, 'Fecha errada o faltante.')
				}
				if (dts.eventos && dts.eventos.masReciente) {
					evReIdentificador = texto.obligatorio(dts.eventos.masReciente.identificador, 'Identificador de evento errado o faltante.')
				}
				if (dts.eventos && dts.eventos.masAntiguo) {
					evAnFecha = fecha.obligatorio(dts.eventos.masAntiguo.fecha, 'Fecha errada o faltante.')
				}
				if (dts.eventos && dts.eventos.masAntiguo) {
					evAnIdentificador = texto.obligatorio(dts.eventos.masAntiguo.identificador, 'Identificador de evento errado o faltante.')
				}
				if (dts.eventos) {
					evCanTot = número.obligatorio(dts.eventos.cantidadTotal, 'Cantidad de eventos errada o faltante.')
				}
			} catch (e) {
				console.error(e)
			}

			let datos: IEnte = {
				identificador: d.id,
				correoElectronico: correoElectronico,
				numeroTelefonico: numeroTelefonico,
				eventos: {
					masReciente: {
						fecha: evReFecha,
						identificador: evReIdentificador
					},
					masAntiguo: {
						fecha: evAnFecha,
						identificador: evAnIdentificador
					},
					cantidadTotal: evCanTot
				}
			}

			return datos
		}

		try {
			let ref = RefCuenta.collection('gruposDeEntes').doc(grupoDeEntes).collection('entes')
			await listar(this.estado, ref, indicePagina || 0, entes.estadoFrio.paginacion.paginas, constructor)
		} catch (e) {
			return rechazar(error.n('[7322] Ocurrió un error.', e))
		}

		return Promise.resolve()
	},
	listarPaginaAnterior: async function(grupoDeEntes: string) {
		return this.listar(grupoDeEntes, this.estado.paginacion.indiceActual-1)
	},
	listarPaginaSiguiente: async function(grupoDeEntes: string) {
		return this.listar(grupoDeEntes, this.estado.paginacion.indiceActual+1)
	}
}

export const reglas = {
	estado: reactive({
		guardando: false
  }),
	guardar: async function(datos: any) {
		this.estado.guardando = true

		try {
			await POST('/reglas', datos)
			notificación.correcto('¡Reglas guardadas!')
		} catch (e) {}

		this.estado.guardando = false
	}
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


const listar = async (
	estado: EstadoListado,
	ref: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>,
	indice: number,
	paginas: any,
	constructor: (d: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => any,
) => {
	estado.listando = true

	let rechazar = (e: IError) => {
		estado.listando = false
		notificación.error(e.msj)
		return Promise.reject()
	}

	let q: firebase.firestore.Query<firebase.firestore.DocumentData>
	let querySnapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>

	if (
		!estado.paginacion ||
		!estado.paginacion.ordenarPor ||
		estado.paginacion.cantidadPorPagina < 1 ||
		estado.paginacion.cantidadPorPagina > 1000 ||
		(estado.paginacion.orden !== 'desc' && estado.paginacion.orden !== 'asc') ||
		(indice < 0 || indice > paginas.length || isNaN(indice) || !isFinite(indice))
	) {
		return rechazar(error.n('[5732] Ocurrió un error.'))
	}

	q = ref.orderBy(estado.paginacion.ordenarPor, estado.paginacion.orden as firebase.firestore.OrderByDirection)

	if (indice === 0 && paginas.length === 0) {
		// Nada que hacer
	} else if (indice === paginas.length && paginas.length > 0) {
		q = q.startAfter(paginas[paginas.length-1].docFinal)
	} else if (indice < paginas.length) {
		q = q.startAt(paginas[indice].docInicial)
	} else {
		return rechazar(error.n('[5733] Ocurrió un error.'))
	}

	try {
		querySnapshot = await q.limit(estado.paginacion.cantidadPorPagina).get()
	} catch (e) {
		return rechazar(error.n('[5734] Ocurrió un error.'))
	}

	if (querySnapshot.size < estado.paginacion.cantidadPorPagina) {
		estado.paginacion.indiceFinal = indice
	}

	if (indice === paginas.length && querySnapshot.empty) {
		estado.paginacion.indiceFinal = indice > 0 ? indice-1 : 0
		notificación.informacion('No hay más datos.')
		estado.listadoInicializado = true
		estado.listando = false
		return Promise.resolve()
	}

	estado.listado = []

	try {
		for (let d of querySnapshot.docs) {
			estado.listado.push(constructor(d))
		}
	} catch (e) {
		estado.listado = []
		return rechazar(error.asegurar(e, '[5735] Ocurrió un error.'))
	}

	estado.listando = false
	estado.listadoInicializado = true

	let pgna = {
		docInicial: querySnapshot.docs[0],
		docFinal: querySnapshot.docs[querySnapshot.docs.length - 1]
	}

	if (indice === paginas.length) { // Página siguiente
		paginas.push(pgna)
	}

	estado.paginacion.indiceActual = indice

	let nvgcn = function(i: number, p: string, a: boolean, h: boolean) {
		return { indice: i, pagina: p, activa: a, habilitada: h }
	}

	estado.paginacion.navegacion = []
	if (paginas.length < 16) {
		for (let i = 0 ; i < paginas.length ; i++) {
			estado.paginacion.navegacion.push(nvgcn(i, (i+1).toString(), i === estado.paginacion.indiceActual, true))
		}
	} else {
		if (estado.paginacion.indiceActual < 8) {
			for (let i = 0 ; i < 13 ; i++) {
				estado.paginacion.navegacion.push(nvgcn(i, (i+1).toString(), i === estado.paginacion.indiceActual, true))
			}
			estado.paginacion.navegacion.push(nvgcn(-1, '...', false, false))
			estado.paginacion.navegacion.push(nvgcn(paginas.length-1, paginas.length.toString(), false, true))
		} else if (estado.paginacion.indiceActual > paginas.length-7) {
			estado.paginacion.navegacion.push(nvgcn(0, '1', false, true))
			estado.paginacion.navegacion.push(nvgcn(-1, '...', false, false))
			for (let i = paginas.length-13 ; i < paginas.length ; i++) {
				estado.paginacion.navegacion.push(nvgcn(i, (i+1).toString(), i === estado.paginacion.indiceActual, true))
			}
		} else {
			estado.paginacion.navegacion.push(nvgcn(0, '1', false, true))
			estado.paginacion.navegacion.push(nvgcn(-1, '...', false, false))
			for (let i = estado.paginacion.indiceActual-4 ; i < estado.paginacion.indiceActual+4 ; i++) {
				estado.paginacion.navegacion.push(nvgcn(i, (i+1).toString(), i === estado.paginacion.indiceActual, true))
			}
			estado.paginacion.navegacion.push(nvgcn(-1, '...', false, false))
			estado.paginacion.navegacion.push(nvgcn(paginas.length-1, paginas.length.toString(), false, true))
		}
	}

	return Promise.resolve()
}

interface PaginacionPagina {
	docInicial: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
	docFinal: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
}

interface Paginacion {
	navegacion: Array<{
		indice: number
		pagina: string
		activa: boolean
		habilitada: boolean
	}>
	ordenarPor: string
	orden: string
	cantidadPorPagina: number
	indiceActual: number
	indiceFinal: number
}

interface EstadoListado {
	listado: IEvento[] | IEnte[]
	listando: boolean
	listadoInicializado: boolean
	paginacion: Paginacion
}


